<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
export default {
	created() {
		// 如果是开发请注释掉下方代码
		// 判断是否是移动端
		if (this.handleIsMobile() && window.location.host != "m.hzjuxiu.com") {
			// 如果是移动端，跳转到移动端页面
			window.location.href = 'http://m.hzjuxiu.com/';
		} else if(!this.handleIsMobile() && window.location.host != "www.hzjuxiu.com"){
			// 如果是PC端，跳转到PC端页面
			window.location.href = 'http://www.hzjuxiu.com/';
		}
	},
	methods: {
		// 判断是否是移动端
		handleIsMobile() {
			return window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
		}
	},
}
</script>
<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	width: 100%;
	height: 100%;
	overflow: hidden;

	>div {
		height: 100%;
		width: 100%;
	}
}

body,
div {
	margin: 0;
	padding: 0;
}

body {
	overflow: hidden;
	height: 100vh;
	width: 100vw;
	font-family: "-apple-system", "BlinkMacSystemFont", "PingFang SC", "Source Han Sans CN", "Helvetica", "Hiragino Sans GB", "Arial", "微软雅黑", "sans-serif" !important;
}

* {
	box-sizing: border-box;
}

nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}</style>
