<template>
	<div class="home-page" ref="homePage" :style="{'--bottom': lineBottom}" id="outer">
		<div class="navBar">
			<div class="juxiuLogo"></div>
			<div id="icon" class="menu-icon" @click="clickMenu()"></div>
		</div>
		<menuNav @flag="changeFlag" class="menu" id="menu" v-if="flag === true"></menuNav>
		<div class="empty-box" v-if="isIOS"></div>
		<router-view />
		<footerBlock></footerBlock>
	</div>
</template>
  
<script>
import officebodyContent from '@/components/mobile/officebodyContent.vue'
import footerBlock from '@/components/mobile/footerBlock.vue'
import menuNav from '@/components/mobile/menuNav.vue'
export default {
	name: 'HomeView',
	components: {
		officebodyContent,
		menuNav,
		footerBlock
	},
	watch:{
    '$route':function(to,from){
	  if(this.$refs.homePage.scrollTop > 0){
		this.$refs.homePage.scrollTop = 0
	  }
    }
  },
	data() {
		return {
			flag: false,//是否点击菜单
			idx: 0,//当前的idx
			isIOS: false,
			lineBottom:''
		}
	},
	created() {
		// this.handleClient();
	},
	mounted(){
		var ua = navigator.userAgent.toLowerCase();
		//判断是否是苹果手机，是则是true
		var isIos = ua.indexOf("iphone") != -1 || ua.indexOf("ipad") != -1;
		if (isIos) {
			this.lineBottom = "74px";
		} else {
			this.lineBottom = "56px";
		}

		//点击菜单外区域收起菜单
		document.addEventListener('click',(event) => {
      var outer = document.getElementById('outer')
      var menu = document.getElementById('menu')
			var icon = document.getElementById('icon')
			if(menu){
				if(menu.contains(event.target)){//菜单内
					this.flag=true;
				}else{//菜单外
					if(outer.contains(event.target) && !icon.contains(event.target)){
						this.flag=false;
					}else{
						this.flag=true;
					}
				}
			}
		},true)
	},
	methods: {
		handleClient() {
			let ua = navigator.userAgent.toLowerCase();
			//判断是否是苹果手机，是则是true
			let isIos = ua.indexOf("iphone") != -1 || ua.indexOf("ipad") != -1;
			
			this.isIOS = isIos;
		},
		cooperateUs() {
			this.$router.push('/CaseTogether')
		},
		clickMenu() {
			this.flag = !this.flag
		},
		saveIdx(data) {
			this.idx = data
		},
		changeFlag(data) {
			this.flag = data
		},
	},
}
</script>
<style lang="scss" scoped>
.home-page {
	height: 100%;
	width: 100%;
	margin-top: 80px;
	overflow: auto;
	padding-bottom:  var(--bottom);;
	.navBar {
		position: fixed;
		top: 0;
		left: 0;
		height: 80px;
		width: 100%;
		background: #FFFFFF;
		box-shadow: 0px 3px 25px 5px rgba(181, 183, 203, 0.25);
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.juxiuLogo {
			position: absolute;
			background: url('/public/img/navlogo.png');
			background-size: 100% 100%;
			width: 116px;
			height: 32px;

			//垂直居中
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
		}

		.menu-icon {
			width: 40px;
			height: 40px;
			background-image: url("/public/imgmobile/menu.png");
			background-size: 100% 100%;
			position: absolute;
			right: 40px;
			cursor: pointer;
		}

		.tolinkUs {
			cursor: pointer;
			height: 100%;
			width: 6.67%;
			height: 100%;
			background: linear-gradient(269deg, #FF8D36 0%, #F2BD61 100%);
			font-size: 16px;
			font-family: Source Han Sans CN Medium;
			color: #FFFFFF;
			line-height: 80px;

			&::after {
				content: '';
				display: block;
				position: absolute;
				top: -36px;
				left: -100px;
				background: white;
				width: 50px;
				height: 125px;
				opacity: 20%;
				transform: rotate(-45deg);
			}

			&:hover::after {
				left: 120%;
				transition: all 2s cubic-bezier(0.3, 1, 0.2, 1);
			}
		}
	}

	.menu {
		position: absolute;
		right: 0px;
		top: 80px;
		z-index: 999;
		width: 45%;
		height: calc(100% - 80px);
	}

	.empty-box {
		padding-top: 20px;
	}

}
</style>
  