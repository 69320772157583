<template>
  <div class="mainpage">
    <div class="mainView">
        <div class="juxiuTitle">数智治理与数智云服务的先行者</div>
    </div>
    <div class="businessInfo">
        <div class="business_title">
            <div class="title">1+N：领域架构</div>
            <div class="subtext">多跨融合 智慧治理</div>
        </div>
        <div class="jxsmartClound">
            <div class="smartName">聚秀数智云</div>
        </div>
        <div class="smartBg">
            <template v-for="(e,index) in businessArr">
                <div class="business_item" :key="index" :style="{'left':index<3?`calc(${index*20}% - ${index*30+90}px)`:'unset','right':index>2?`calc(${(index-3)*20}% - ${index*40-30}px)`:'unset',
                    'bottom':`calc(50% - ${(index>2?index-3:index)*(22)}%` }"
                >
                    {{e}}
                </div>
            </template>
        </div>
    </div>
    <div class="advantage">
        <div class="business_title">
            <div class="title">万千客户的信任与选择</div>
            <div class="subtext">全国各省市布局，服务政府国企数字化转型</div>
        </div>
        <div class="business_info">
            <div class="business_block">
                <div class="title"><span class="numberStyle">10</span><span class="wordStyle">年专业领域</span></div>
                <div class="subtext">软件行业经验丰富，提供最精准的解决方案</div>
            </div>
            <div class="business_block">
                <div class="title"><span class="numberStyle">4</span><span class="unit">w+</span><span class="wordStyle">合作用户</span></div>
                <div class="subtext">广受业内认可，品牌值得信赖</div>
            </div>
        </div>
    </div>
    <div class="thenews">
        <div class="business_title">
            <div class="title">资讯动态</div>
            <div class="subtext">关注聚秀发展，聚焦行业动态</div>
        </div>    
        <div class="content">
            <div class="left_photo">
                <el-carousel trigger="click" @change='change'>
                    <el-carousel-item v-for="(item,index) in newsArr" :key="index" >
                        <!-- <img :src="item.url" /> -->
                        <el-image
                            style="width: 100%; height: 100%"
                            :src="require('/public/img/dynamic/' + item.img)"
                            fit="fit">
                        </el-image>
                        <!-- <div class="text">{{item.text}}</div> -->
                    </el-carousel-item>
                </el-carousel>
                
            </div>
            <div class="right_content">
                <div class="right_block">
                    <div class="content_row" v-for="(item,index) in newsArr" :key="index">
                        <div class="date">{{item.time}}</div>
                        <div @click="onCardClick(item)" :style="currentIndex==index?'color:#FF795B':''" class="newscontent">{{item.title}}</div>
                    </div>
                </div>    
                <div class="content_more" @click="gotoMore">
                    <div class="more_btn">
                        查看更多 
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {micData} from '@/views/dynamic/micData'
export default {
    data(){
        return{
            currentIndex:0,
            businessArr:['数智国资','智慧国企','资产共享云','人工智能应用','智慧监管','智慧好管家'],
            newsArr:[
                // {date:'2022-11-12',text:"浙江省国资委推出国资交易质量提升“十件事”",url:'/img/news/news1.png'},
                // {date:'2022-11-12',text:'完善平台智能录入、统计、检索、客服、审核、监测和线上签约、线上鉴证、资金结算等功能，提高平台智能化水平',url:'/img/news/news2.png'},
                // {date:'2022-11-12',text:'浙江省国资委推出国资交易质量提升“十件事”',url:'/img/news/news3.png'}
            ]
        }
    },
    mounted(){
       this.newsArr = micData.slice(0,3)
    },
    methods:{
        gotoMore(){
            this.$router.push('./caseDynamic')
        },
        onCardClick(item) {
            window.open(item.url)
        },
        change(item,item1){
            this.currentIndex = item
        }
    }
}
</script>

<style lang="scss" scoped>
.mainpage{
    height: 100%;
    width: 100%;
    overflow: hidden;
    .mainView{
        background: url('/public/img/mainimg.png');
        background-size: 100% 100%;
        width: 100%;
        height: 628px;
        position: relative;
        .juxiuTitle{
            position: absolute;
            left: 9.38%;
            top: 50%;
            transform: translate(0,-50%);
            font-size: 57px;
            font-family: PangMenZhengDao;
            color: #333131;
            line-height: 65px;
            letter-spacing: 4px;
        }
    }
    .businessInfo{
        padding-top: 36px;
        width: 100%;
        height: 640px;
        position: relative;
        display: flex;
        justify-content: center;
        .business_title{
            .title{
                font-size: 32px;
                font-family: Source Han Sans CN Medium;
                font-weight: 600;
                color: #333333;
                line-height: 45px;
            }
            .subtext{
                padding-top: 11px;
                font-size: 18px;
                font-family: Source Han Sans CN Normal;
                font-weight: 400;
                color: #666666;
            }
        }
        .jxsmartClound{
            position: absolute;
            background: url('/public/img/jxszy.png');
            background-size: 100% 100%;
            top: 162px;
            left: calc(50% - 169px);
            width: 338px;
            height: 218px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            .smartName{
                font-size: 24px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 33px;
                margin-bottom: -55px;
            }
        }
        .smartBg{
            position: absolute;
            background: url('/public/img/szybg.png');
            background-size: 100% 100%;
            top: calc(50% - 110px);
            left: calc(50% - 31%);
            width: 62%;
            height: 361px;
            .business_item{
                cursor: pointer;
                width: 180px;
                height: 45px;
                display: flex;
                padding-left: 50px;
                // justify-content: center;
                align-items: center;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 25px;
                background: url('/public/img/businessBlock.png');
                background-size: 100% 100%;
                position: absolute;
                &::after{
                        content: '';
                        display: block;
                        position: absolute;
                        top: -36px;
                        left: -100px;
                        background: white;
                        width: 50px;
                        height: 125px;
                        opacity: 20%;
                        transform: rotate(-45deg);
                    }
                    &:hover::after{
                        left: 120%;
                        transition: all 2s cubic-bezier(0.3, 1, 0.2, 1);
                    }
            }
        }
    }
    .advantage{
        width: 100%;
        height: 640px;
        background: url('/public/img/ouradvantage.png');
        background-size: 100% 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        .business_title{
            padding-top: 60px;
            .title{
                font-size: 32px;
                font-family: Source Han Sans CN Medium;
                font-weight: 600;
                color: #333333;
                line-height: 45px;
            }
            .subtext{
                padding-top: 11px;
                font-size: 18px;
                font-family: Source Han Sans CN Normal;
                font-weight: 400;
                color: #666666;
            }
        }
        .business_info{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 16.9%;
            padding-top: 90px;
            .business_block{
                text-align: left;
                .title{
                    font-size: 16px;
                    font-family: Source Han Sans CN Bold;
                    font-weight: 600;
                    color: #212121;
                    line-height: 22px;
                }
                .unit{
                    color: #FF795B;
                    font-family: Source Han Sans CN Bold;
                    font-weight: 600;
                }
                .numberStyle{
                    font-size: 40px;
                    font-family: Source Han Sans CN Bold;
                    font-weight: 600;
                    color: #FF795B;
                    line-height: 56px;
                }
                .subtext{
                    font-size: 14px;
                    font-family: Source Han Sans CN Regular;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;
                }
                .wordStyle{
                    padding-left: 12px;
                }
            }
            .business_block + .business_block{
                padding-top: 60px;
            }
        }
    }
    .thenews{
        width: 100%;
        height: 720px;
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom: 122px;
        .business_title{
            padding-top: 60px;
            .title{
                font-size: 32px;
                font-family: Source Han Sans CN Medium;
                font-weight: 600;
                color: #333333;
                line-height: 45px;
            }
            .subtext{
                padding-top: 11px;
                font-size: 18px;
                font-family: Source Han Sans CN Normal;
                font-weight: 400;
                color: #666666;
            }
        }
        .content{
            width: 100%;
            height: calc(100% - 142px);
            padding: 2.8% 16.67% 0 16.67%;
            display: flex;
            justify-content: space-between;
        }
        .left_photo{
            width: 42%;
            height: 100%;
            display: flex;
            .el-carousel{
                width: 100%;
                height: calc(100% - 0.3rem) !important;
            }
            .el-carousel__container{
                height: calc(100% - 0px) !important;
            }
            .el-carousel__item {
                width: 100%;
                height: 100%;
                img{
                    width: 100%;
                }
            }
            .el-carousel__indicators--horizontal {
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
            }
            .text{
                position: absolute;
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 22px;
                bottom: -20px;
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
        .right_content{
            padding-left: 100px;
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            .right_block{
                height: calc(100% - 60px);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
            .content_more{
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .more_btn{
                    cursor: pointer;
                    width: 144px;
                    height: 60px;
                    background: url('/public/img/morebtnbg.png');
                    background-size: 100% 100%;
                    font-size: 16px;
                    font-family: Source Han Sans CN Medium;
                    color: #FFFFFF;
                    line-height: 22px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    &::after{
                        content: '';
                        display: block;
                        position: absolute;
                        top: -36px;
                        left: -100px;
                        background: white;
                        width: 50px;
                        height: 125px;
                        opacity: 20%;
                        transform: rotate(-45deg);
                    }
                    &:hover::after{
                        left: 120%;
                        transition: all 2s cubic-bezier(0.3, 1, 0.2, 1);
                    }
                }
            }
            .content_row{
                border-top: 1px solid #D4D4D4;
                padding: 15px 0;
                width: 100%;
                flex: 1;
                
                
                .date{
                    text-align: left;
                    font-size: 14px;
                    font-family: Source Han Sans CN Regular;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;
                    padding-bottom: 16px;
                }
                .newscontent{
                    font-size: 16px;
                    font-family: Source Han Sans CN Bold;
                    font-weight: 600;
                    color: #333333;
                    line-height: 22px;
                    cursor: pointer;
                    text-align: left;
                }
                &:nth-last-child(n){
                    border-bottom: 1px solid #D4D4D4;
                }
                &:hover{
                    .date{
                        color: #333;
                        font-weight: 700;
                    }
                    .newscontent{
                        color: rgb(40,120,255) !important;
                    }
                }
            }
        }
    }
    
}
</style>