export const micData = [
    {
        title:'CCF YOCSEF杭州举办第二十四届学术委员会第二次会议暨换届选举会议，聚秀科技创始人韩志科当选候任副主席！',
        time:'2024-01-17 09:36',
        img:'资讯87.jpg',
        url:'https://mp.weixin.qq.com/s/ZneBC6Ii4hCrwstFl8Boew'
    },
    {
        title:'国务院国资委主任张玉卓：加快提升国资监管智能化水平',
        time:'2023-02-03 10:10',
        img:'资讯86.jpg',
        url:'https://mp.weixin.qq.com/s/G93XZvN8KOlIcQ7MkGLBag'
    },
    {
        title:'海山号|定海区财政局搭建“数字平台”点亮“智慧国资”',
        time:'2023-01-29 09:00',
        img:'资讯85.png',
        url:'https://mp.weixin.qq.com/s/z0FlLqUYf7x2DRffBxHw8A'
    },
    {
        title:'一图读懂国有企业“一利五率”经营指标体系',
        time:'2023-01-15 15:43',
        img:'资讯84.png',
        url:'https://mp.weixin.qq.com/s/Cr8mcI_901xsjqhObDLxdQ'
    },
    {
        title:'聚秀2023再出发，新架构·新产品·新官网同步首发！',
        time:'2023-01-14 19:41',
        img:'资讯83.jpg',
        url:'https://mp.weixin.qq.com/s/JDVjqqJeZwh1RsS0OOYcQg'
    },
    {
        title:'歌画东阳：数字化转型赋能东阳市国资国企改革发展',
        time:'2023-01-13 18:12',
        img:'资讯82.png',
        url:'https://mp.weixin.qq.com/s/wwFMkYIttD320WuqqVTyiA'
    },
    {
        title:'新华网：杭州滨江财政国资国企数字化改革筑牢国资安全防火墙',
        time:'2023-01-11 15:00',
        img:'资讯81.png',
        url:'https://mp.weixin.qq.com/s/9gxaq_Q3rZCZwsILJX5GbA'
    },
    {
        title:'浙江机关事务数字化建设试点全面启动 机关运行保障现代化先行开局起步',
        time:'2023-01-06 15:59',
        img:'资讯80.jpg',
        url:'https://mp.weixin.qq.com/s/mIaykJIEY-Xm9-u4TShtrQ'
    },
    {
        title:'对标世界一流企业的误区与破解',
        time:'2022-12-06 11:30',
        img:'资讯79.jpg',
        url:'https://mp.weixin.qq.com/s/oagVBtEc8sKTGx-iqkf1kQ'
    },
    {
        title:'富阳财政打造“智慧大脑”新引擎，聚秀科技数智化赋能富阳区智慧国资建设！',
        time:'2022-12-06 11:30',
        img:'资讯78.png',
        url:'https://mp.weixin.qq.com/s/PAOI46eQOpgHiqAt4hOnhw'
    },
    {
        title:'一图读懂丨《关于提高中央行政事业单位国有资产管理效能 坚持勤俭办一切事业的实施意见》',
        time:'2022-12-03 10:04',
        img:'资讯77.png',
        url:'https://mp.weixin.qq.com/s/LF-XXImeHp0yhodhizgiew'
    },
    {
        title:'浙江省财政厅关于印发浙江省行政事业性国有资产管理办法的通知',
        time:'2022-12-02 10:00',
        img:'资讯76.png',
        url:'https://mp.weixin.qq.com/s/p8PVAkv-Q8r2SzWZJW6Hmg'
    },
    {
        title:'浙江省财政厅关于印发浙江省省级行政事业单位房产出租管理办法的通知',
        time:'2022-11-30 16:15',
        img:'资讯75.png',
        url:'https://mp.weixin.qq.com/s/BYM3iHbtk8oJxi1E2IrF-A'
    },
    {
        title:'党委会议事规则（国有企业适用）',
        time:'2022-11-28 14:30',
        img:'资讯74.png',
        url:'https://mp.weixin.qq.com/s/eyFkR_Eb-0THqxlqemkIOg'
    },
    {
        title:'浙里好管家，在数字政府跑道精彩亮相！',
        time:'2022-11-26 09:30',
        img:'资讯73.jpg',
        url:'https://mp.weixin.qq.com/s/6CABiqtdfzigPonTuGCi-Q'
    },
    {
        title:'一图速览2017—2021年浙江省国有资产管理工作成效',
        time:'2022-11-25 09:00',
        img:'资讯72.png',
        url:'https://mp.weixin.qq.com/s/15rZRh0YrjFchvxn8-tijg'
    },
    {
        title:'国管局印发《关于提高中央行政事业单位国有资产管理效能 坚持勤俭办一切事业的实施意见》',
        time:'2022-11-24 10:30',
        img:'资讯71.png',
        url:'https://mp.weixin.qq.com/s/iwJIXir4ptvlGTotDAerlA'
    },
    {
        title:'聚秀科技上线资产云浙政钉、微信小程序双线移动端应用！',
        time:'2022-11-22 12:00',
        img:'资讯70.png',
        url:'https://mp.weixin.qq.com/s/tow6esL7QbOjZ3HW6Z8FhA'
    },
    {
        title:'聚秀科技助力浙江省数字化改革“最系列”成果 | 为两项“最佳应用”、一个“最强大脑”增光添彩！',
        time:'2022-11-17 09:56',
        img:'资讯69.png',
        url:'https://mp.weixin.qq.com/s/kaRtL5EFGO0TVV0tL_mRhw'
    },
    {
        title:'资产云2.0「聚秀行政事业单位资产管理」常见问题解答',
        time:'2022-11-16 09:00',
        img:'资讯68.png',
        url:'https://mp.weixin.qq.com/s/Pr3j0ZzJrKDVOl5cvFYmVg'
    },
    {
        title:'万亿国资装上“智”引擎 I 湖州市数智化国资监管平台获《湖州日报》专版刊登！',
        time:'2022-11-12 09:10',
        img:'资讯67.png',
        url:'https://mp.weixin.qq.com/s/ZAsOTsZRZhHT2l3kCoh38Q'
    },
    {
        title:'保运转 提效能 抓融合 以盘活存量资产落实勤俭办一切事业——国管局深入推进中央行政事业单位公物仓建设',
        time:'2022-11-10 09:40',
        img:'资讯66.jpg',
        url:'https://mp.weixin.qq.com/s/Su9j0fydMjKEvLpk86eaVA'
    },
    {
        title:'国资委研究中心报告：央企改革实现三个历史性突破',
        time:'2022-11-09 09:48',
        img:'资讯65.jpg',
        url:'https://mp.weixin.qq.com/s/NGOZj6Gt52fmWS1YqeQCbw'
    },
    {
        title:'数字化转型三阶段：信息化-数字化-数智化',
        time:'2022-11-08 11:40',
        img:'资讯64.jpg',
        url:'https://mp.weixin.qq.com/s/E9KlaSoSWiKJdwD835kzDg'
    },
    {
        title:'让闲置资产归仓盘活用好！聚秀科技助力湘潭市获批国家级公物仓创新试点建设单位',
        time:'2022-11-07 09:55',
        img:'资讯63.png',
        url:'https://mp.weixin.qq.com/s/I2r85UeCSXPK2Y2_17KC_g'
    },
    {
        title:'（赶紧收藏）国有企业三项制度改革模型和操作咨询手册',
        time:'2022-11-05 09:00',
        img:'资讯62.jpg',
        url:'https://mp.weixin.qq.com/s/AfpaRnpar4sK8Aj1fDTrXg'
    },
    {
        title:'阿里达摩院五年磨一剑，牵头推出 AI 模型社区“魔搭”，开源 300+ 顶尖模型',
        time:'2022-11-04 15:30',
        img:'资讯61.jpg',
        url:'https://mp.weixin.qq.com/s/zTSfFNpz7SfT0P_SSVdTRA'
    },
    {
        title:'余杭区国资国企数字化平台建设获财政部报道！',
        time:'2022-11-03 18:01',
        img:'资讯60.png',
        url:'https://mp.weixin.qq.com/s/OGbfkRixDYOBhQttHXvzCQ'
    },
    {
        title:'财政部关于盘活行政事业单位国有资产的指导意见',
        time:'2022-11-02 09:30',
        img:'资讯59.jpg',
        url:'https://mp.weixin.qq.com/s/EbUDKG8AKi7anh05W-O9Iw'
    },
    {
        title:'【连载六】国有资本地方投融资平台改造之路',
        time:'2022-10-28 09:30',
        img:'资讯49.jpg',
        url:'https://mp.weixin.qq.com/s/PIjESu-3o0xLHjaKtt4ISQ'
    },
    {
        title:'二十大报告全文发布 逐句解读国资国企改革重点内容',
        time:'2022-10-27 10:30',
        img:'资讯58.jpg',
        url:'https://mp.weixin.qq.com/s/kXjDRwt-8RR0I81bc5SBuQ'
    },
    {
        title:'国务院国资委 财政部令第32号 企业国有资产交易监督管理办法',
        time:'2022-10-26 09:35',
        img:'资讯57.png',
        url:'https://mp.weixin.qq.com/s/WclldndTz4Fg76Q5W4yXEA'
    },
    {
        title:'用好大数据 监督更有力丨聚秀助力江山市打造国有房产领域公权力大数据监督应用，该应用获中央纪委国家监委关注！',
        time:'2022-10-25 17:59',
        img:'资讯56.png',
        url:'https://mp.weixin.qq.com/s/fwFgq7qh2H0_uZHD7M9hgg'
    },
    {
        title:'毅行湘湖，聚秀科技举行2022四季度冲刺活动！',
        time:'2022-10-24 11:17',
        img:'资讯55.jpg',
        url:'https://mp.weixin.qq.com/s/3oJsjH7RBT4m5Uaq1mT5kw'
    },
    {
        title:'【连载五】如何打造国有资本的投资管理体系及做好投后管理',
        time:'2022-10-22 15:00',
        img:'资讯49.jpg',
        url:'https://mp.weixin.qq.com/s/6cbAOEtLayMsZ3Wktgc7Gg'
    },
    {
        title:'公司要闻 I  聚秀科技核心技术成员获一项机器学习领域的国家发明专利！',
        time:'2022-10-21 15:30',
        img:'资讯47.png',
        url:'https://mp.weixin.qq.com/s/M0wkMErHhg6PWrh-2yNT1A'
    },
    {
        title:'财政部会计司有关负责人就印发《行政事业单位划转撤并相关会计处理规定》答记者问',
        time:'2022-10-20 09:19',
        img:'资讯54.png',
        url:'https://mp.weixin.qq.com/s/R9W2IslZGIe3CxtPdUD79A'
    },
    {
        title:'【定责定岗定编定员定额定薪】指导手册，赶快收藏',
        time:'2022-10-19 09:00',
        img:'资讯53.jpg',
        url:'https://mp.weixin.qq.com/s/ckQGJv2uvl0nOxuxmF5nHA'
    },
    {
        title:'国管局在浙江开展机关事务数字化建设试点工作',
        time:'2022-10-18 09:00',
        img:'资讯22.png',
        url:'https://mp.weixin.qq.com/s/qsYRAKuu-wSYsO8poN-duw'
    },
    {
        title:'中国共产党第二十次全国代表大会在京开幕　一图速览二十大报告',
        time:'2022-10-17 09:52',
        img:'资讯52.jpg',
        url:'https://mp.weixin.qq.com/s/8yhudWbBTx6q0XRr81sRiA'
    },
    {
        title:'公司要闻 I 聚秀科技又获一项国家发明专利！',
        time:'2022-10-14 09:00',
        img:'资讯51.png',
        url:'https://mp.weixin.qq.com/s/y-VU8ovI8dRvDMISnt2BAw'
    },
    {
        title:'喜报！聚秀科技荣获2022年浙江省AA级“守合同重信用”企业',
        time:'2022-10-13 09:00',
        img:'资讯50.png',
        url:'https://mp.weixin.qq.com/s/XcNTRSC7L6MpjbBMfjugNQ'
    },
    {
        title:'【连载四】打造世界一流企业是中国复兴的关键一跳',
        time:'2022-10-12 09:00',
        img:'资讯49.jpg',
        url:'https://mp.weixin.qq.com/s/6rU-B-_XcoEPoYctsIqC9A'
    },
    {
        title:'数字赋能 I 聚秀助力温州机关打造国管局“两化融合样板”',
        time:'2022-10-11 13:30',
        img:'资讯36.jpg',
        url:'https://mp.weixin.qq.com/s/tvPC9kNc0Ey__JXxxaNqvw'
    },
    {
        title:'【连载三】构建创新驱动联合体，系统打造区域创新驱动优势',
        time:'2022-10-10 17:51',
        img:'资讯49.jpg',
        url:'https://mp.weixin.qq.com/s/do3loPjjRRno_fculu1xGg'
    },
    {
        title:'【连载二】新形势下国资国企改革的14个突破',
        time:'2022-10-08 17:30',
        img:'资讯49.jpg',
        url:'https://mp.weixin.qq.com/s/FpMTEG9PBNQDWBVDIBQKng'
    },
    {
        title:'【连载一】国资国企改革3.0的十大特点',
        time:'2022-09-30 09:15',
        img:'资讯49.jpg',
        url:'https://mp.weixin.qq.com/s/qxI7SZKmv1Xe5o6ydPHs0g'
    },
    {
        title:'（建议收藏）行政资产管理应用功能优化汇总',
        time:'2022-09-29 09:00',
        img:'资讯48.png',
        url:'https://mp.weixin.qq.com/s/KiWk2n7dDo_zxiohkef_Nw'
    },
    {
        title:'国有企业党委、董事会、经理层的关系是什么',
        time:'2022-09-28 09:01',
        img:'资讯47.png',
        url:'https://mp.weixin.qq.com/s/lXAY0kHZQfib-N_kyTShkA'
    },
    {
        title:'搭建平台 完善机制 浙江加快构建国资监管大格局',
        time:'2022-09-27 09:00',
        img:'资讯46.png',
        url:'https://mp.weixin.qq.com/s/jPzGxq18M-So_wwlLvlDIw'
    },
    {
        title:'公权力大数据监督工作下一步怎么干？杭州这场推进会提出最新要求',
        time:'2022-09-26 09:08',
        img:'资讯45.jpg',
        url:'https://mp.weixin.qq.com/s/Mf7FdA-zhZZ1M_sBDa4YVw'
    },
    {
        title:'三张图读懂全省机关事务系统务虚会“关键信息”',
        time:'2022-09-22 09:38',
        img:'资讯44.png',
        url:'https://mp.weixin.qq.com/s/CELE9kigXpWDuSpvkjwepw'
    },
    {
        title:'找准跑道、多维发力，浙江国资国企跑出改革“加速度”',
        time:'2022-09-15 09:30',
        img:'资讯43.jpg',
        url:'https://mp.weixin.qq.com/s/-4OoAvGWvS0gIPzkMHXRUQ'
    },
    {
        title:'国企改革三年行动冲刺收官：各中央企业和各地改革工作台账完成率均超过98%',
        time:'2022-09-14 09:53',
        img:'资讯42.jpg',
        url:'https://mp.weixin.qq.com/s/-qoca02qvnBosSDfsakdUg'
    },
    {
        title:'解读国企“上云”潮：什么是国资云、国家云、国资监管云？',
        time:'2022-09-13 10:35',
        img:'资讯41.jpg',
        url:'https://mp.weixin.qq.com/s/lP2eaxL0NfFQkvXECnJc6Q'
    },
    {
        title:'国资委最新要求！国有上市公司要对标、赶超同行业头部企业，加大授权放权力度',
        time:'2022-09-08 09:30',
        img:'资讯40.jpg',
        url:'https://mp.weixin.qq.com/s/-3Bd2Duak-7Y8pvxqbW8dQ'
    },
    {
        title:'杭州城投资本再次考察聚秀科技',
        time:'2022-09-02 13:23',
        img:'资讯39.png',
        url:'https://mp.weixin.qq.com/s/zxnYb-bkssKE0wwTUjN5sg'
    },
    {
        title:'智慧国资 | 杭州市智慧国资房产管理平台专题推进会',
        time:'2022-05-10 09:07',
        img:'资讯38.png',
        url:'https://mp.weixin.qq.com/s/dDHeQ5AxzVRsamrBJ36eVg'
    },
    {
        title:'浙江省国资委推出国资交易质量提升“十件事”',
        time:'2022-04-08 09:15',
        img:'资讯37.jpg',
        url:'https://mp.weixin.qq.com/s/OXZczKakrS0HOAKRQS6D2A'
    },
    {
        title:'“四资”融合“浙里”先行',
        time:'2022-03-30 11:52',
        img:'资讯36.jpg',
        url:'https://mp.weixin.qq.com/s/mk3aMKI545xtj-O8PzbOlQ'
    },
    {
        title:'全面推进！自然资源部就实景三维中国建设明确这些要求',
        time:'2022-03-03 09:55',
        img:'资讯35.png',
        url:'https://mp.weixin.qq.com/s/YhwcZDOLG4VXjpFL2Iw60A'
    },
    {
        title:'聚焦数智管理平台建设，聚秀助力打造杭州财政数字化改革金名片为共同富裕示范区建设赋能',
        time:'2022-02-23 09:32',
        img:'资讯34.png',
        url:'https://mp.weixin.qq.com/s/wvZah0-qRyQqWeLDDSAUaw'
    },
    {
        title:'国资“一盘棋” I 聚秀助力杭州财政为数字化改革增添新功能',
        time:'2022-02-22 13:01',
        img:'资讯34.png',
        url:'https://mp.weixin.qq.com/s/ot4ChV3Tesr0a6mMQZR1KA'
    },
    {
        title:'为什么国企要加快推进数字化转型？',
        time:'2022-02-21 09:30',
        img:'资讯33.jpg',
        url:'https://mp.weixin.qq.com/s/v1R54pkFmGmPpAwdO5fRwQ'
    },
    {
        title:'萧山区财政局荣获2021年度全省财政管理绩效考评优秀单位，我公司两项数字化应用助力！',
        time:'2022-02-14 13:27',
        img:'资讯32.png',
        url:'https://mp.weixin.qq.com/s/GN3skZJ4wfETytGYKyQ_CQ'
    },
    {
        title:'“房小莲”腾退19套公租房',
        time:'2022-02-11 09:00', 
        img:'资讯31.png',
        url:'https://mp.weixin.qq.com/s/KuDKFxXwOEe6thzTrOdMKQ'
    },
    {
        title:'国务院关于印发“十四五”数字经济发展规划的通知',
        time:'2022-02-10 09:35',
        img:'资讯30.jpg',
        url:'https://mp.weixin.qq.com/s/4z65-mhcMvd59Ya2-oCMQg'
    },
    {
        title:'一封来自市国资委的感谢信！',
        time:'2022-01-31 15:00',
        img:'资讯29.png',
        url:'https://mp.weixin.qq.com/s/-CtdS1bZeubqpIAm371KIg'
    },
    {
        title:'3月1日起施行！这部《条例》将保障浙江数字化改革',
        time:'2022-01-25 10:29',
        img:'资讯28.jpg',
        url:'https://mp.weixin.qq.com/s/iLRswl0KsEqFOugMencKVQ'
    },
    {
        title:'中华人民共和国财政部令第108号--事业单位财务规则',
        time:'2022-01-20 11:55',
        img:'资讯27.png',
        url:'https://mp.weixin.qq.com/s/adbXhf59V2spCc7AwHEonw'
    },
    {
        title:'CCF YOCSEF杭州分论坛2022-2023换届会议',
        time:'2022-01-11 10:14',
        img:'资讯26.jpg',
        url:'https://mp.weixin.qq.com/s/waFx9ZaZT_7cRZiEO5DWqQ'
    },
    {
        title:'互联网信息服务算法推荐管理规定',
        time:'2022-01-07 09:40',
        img:'资讯19.jpg',
        url:'https://mp.weixin.qq.com/s/aek_gtu4mxb8Iq4VAffL5A'
    },
    {
        title:'浙交所杭州分中心揭牌！全省统一国有资产交易数字化平台“浙交汇”全面建成',
        time:'2022-01-05 09:00',
        img:'资讯25.png',
        url:'https://mp.weixin.qq.com/s/YN7LrHOfb2KjqmrvuvSX9g'
    },
    {
        title:'关于印发《政府会计准则制度解释第4号》的通知',
        time:'2021-12-31 09:45',
        img:'资讯24.png',
        url:'https://mp.weixin.qq.com/s/6jfrrjc7sjd2Zz7zsaoJRA'
    },
    {
        title:'中央网络安全和信息化委员会印发《“十四五”国家信息化规划》',
        time:'2021-12-29 09:34',
        img:'资讯23.png',
        url:'https://mp.weixin.qq.com/s/Mn0o972LRxo4YQsehPj_Tw'
    },
    {
        title:'《公司法(修订草案)》全文、说明及修改对照',
        time:'2021-12-28 09:00',
        img:'资讯22.png',
        url:'https://mp.weixin.qq.com/s/ysq75Bxf0aquFabQcSCAbQ'
    },
    {
        title:'《浙江省省级行政事业单位软件资产管理暂行办法》政策解读',
        time:'2021-12-27 09:46',
        img:'资讯7.png',
        url:'https://mp.weixin.qq.com/s/Sr2O6bMrjqTRm0Qc2JAWow'
    },
    {
        title:'浙江省财政厅关于印发浙江省省级行政事业单位软件资产管理办法（试行）的通知',
        time:'2021-12-24 10:13',
        img:'资讯21.png',
        url:'https://mp.weixin.qq.com/s/0YkzAKoTSgBGuJRdxMJvgg'
    },
    {
        title:'新征程新蓝图 | 一图读懂舟山市国资国企改革发展“十四五”规划',
        time:'2021-12-21 09:25',
        img:'资讯20.png',
        url:'https://mp.weixin.qq.com/s/d3RuxV_WfCVd1tadrsc9ug'
    },
    {
        title:'一图读懂国资央企2022年度生产经营改革发展和党建工作',
        time:'2021-12-20 09:23',
        img:'资讯19.jpg',
        url:'https://mp.weixin.qq.com/s/s4LVpD0laMrxzKUcGAI2CQ'
    },
    {
        title:'电子公物仓上线，邀您挑选资产咯！',
        time:'2021-12-18 15:53',
        img:'资讯18.png',
        url:'https://mp.weixin.qq.com/s/VoaBlot8O76MJ6QbivtNkA'
    },
    {
        title:'优秀案例>>>廉线·派驻九组 | 对外投资监管 "数字监督"派上用场',
        time:'2021-10-22 18:21',
        img:'资讯17.png',
        url:'https://mp.weixin.qq.com/s/rbVMPJeOyAdvAiSelfws9Q'
    },
    {
        title:'浙江省杭州市余杭财政：国资国企数字化平台落地试运行',
        time:'2021-10-16 14:10',
        img:'资讯16.png',
        url:'https://mp.weixin.qq.com/s/AOOUVoSI5yfhnH5Po72eNQ'
    },
    {
        title:'资产云2.0 | 衢州市本级培训与推广成功举行',
        time:'2021-07-24 15:25',
        img:'资讯15.jpg',
        url:'https://mp.weixin.qq.com/s/fMe_yEHVMTqMY1zonv6zGg'
    },
    {
        title:'数字化改革之小创新：渣土倾倒管控数字化',
        time:'2021-07-01 20:16',
        img:'资讯14.jpg',
        url:'https://mp.weixin.qq.com/s/3tAsMA1M7CgUAIB8xU_sbg'
    },
    {
        title:'计算学院助力“浙江知识产权在线”平台',
        time:'2021-05-05 10:56',
        img:'资讯13.jpg',
        url:'https://mp.weixin.qq.com/s/Hx8tNDXYJSjyynB6XlU6Uw'
    },
    {
        title:'计算学院举办韩志科副教授报告交流会',
        time:'2021-04-30 10:11',
        img:'资讯12.jpg',
        url:'https://mp.weixin.qq.com/s/crxmiRO53O8m0grU4rXJpQ'
    },
    {
        title:'4月26日，“浙江知识产权在线”正式上线啦!',
        time:'2021-04-27 09:39',
        img:'资讯11.png',
        url:'https://mp.weixin.qq.com/s/rP9PmWHCRC3WB29aM_ZgFw'
    },
    {
        title:'一图读懂｜浙江省国资国企数字化改革行动方案',
        time:'2021-04-23 09:54',
        img:'资讯10.png',
        url:'https://mp.weixin.qq.com/s/NLZ39UZAxjTJnsfRoDbAKA'
    },
    {
        title:'市纪委监委、市国资委莅临指导萧山区国资监管工作',
        time:'2021-04-02 19:41',
        img:'资讯9.jpg',
        url:'https://mp.weixin.qq.com/s/-Hcnf-N26jPUSmi5Lr0cIw'
    },
    {
        title:'数字国资 助力国企改革——我区国资统一监管大数据平台正式上线运行',
        time:'2021-03-22 12:59',
        img:'资讯8.png',
        url:'https://mp.weixin.qq.com/s/W_n0vEniUci3e3kDH1W5-g'
    },
    {
        title:'国务院国资委印发《国有企业公司章程制定管理办法》',
        time:'2021-03-12 17:58',
        img:'资讯7.png',
        url:'https://mp.weixin.qq.com/s/EWfpKgUsGkTArduqNJANtA'
    },
    {
        title:'发改委通知《引导社会资本参与盘活国有存量资产》',
        time:'2021-03-11 17:04',
        img:'资讯5.png',
        url:'https://mp.weixin.qq.com/s/UsAa9aSc4YsukSAQ2qD74A'
    },
    {
        title:'加强机关事务数据建设 助推机关运行保障现代化进程',
        time:'2021-03-10 17:56',
        img:'资讯4.png',
        url:'https://mp.weixin.qq.com/s/G6gr_G9tgEODdwRovdxLTg'
    },
    {
        title:'全面推进数字化改革！浙江省委书记袁家军在新春第一会上这样部署',
        time:'2021-03-09 17:33',
        img:'资讯6.png',
        url:'https://mp.weixin.qq.com/s/fHOp7fs5hzoip49Kge8DsQ'
    },
    {
        title:'用城市大脑助推全域数字化改革，杭州这样做',
        time:'2021-03-08 17:25',
        img:'资讯3.jpg',
        url:'https://mp.weixin.qq.com/s/Ndmlp4XUA0M0Y62-euI-8w'
    },
    {
        title:'云原生，未来社会数字化转型的最终归宿!',
        time:'2021-03-05 18:03',
        img:'资讯2.png',
        url:'https://mp.weixin.qq.com/s/zsQ0Y9H4SNVDdik-IYz3rA'
    },
    {
        title:'紧跟节奏，抓住机遇，2021助力发展！',
        time:'2021-02-26 17:22',
        img:'资讯1.png',
        url:'https://mp.weixin.qq.com/s/BhbHv7-_2-F9G5I7xaV-lQ'
    },
]